<template>
  <h1>Tech Audit</h1>
  <div class="row fadeUp topBlue">
    <h2>
      Collect information about the status of your tech infrastructure to:
    </h2>
    <div class="col-1-3">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>
        Ensure users are correctly aligned to hardware to maximise productivity
      </div>
    </div>
    <div class="col-1-3">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>
        Assess warranty &amp; device health for budget and refresh planning
      </div>
    </div>
    <div class="col-1-3">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>Report on hardware specs and usage to rightsize your fleet</div>
    </div>
  </div>
  <div class="row fadeUp delay-50">
    <h2>Audit Methodology</h2>
    <img class="imgFull" src="/img/services/tech-audit-process.png">
  </div>
  <div class="row fadeUp delay-100">
    <button @click="toggleModalDetails">View Detailed Audit Methodology</button>
  </div>
  <div v-if="showModalDetails">
    <Modal theme="modalImage" @close="toggleModalDetails">
      <img
        class="imgFull"
        :src="'/img/services/tech-audit-detailed.jpg'"
        alt="Financial Agreements"
        title="Financial Agreements"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  props: ["id"],
  data() {
    return {
      color: "white",
      job: null,
      showModalDetails: false,
    };
  },
  mounted() {
    fetch("/data/db.json")
      .then((res) => res.json())
      .then((data) => (this.job = data['jobs'][this.id]))
      .catch((err) => console.log(err.message));
  },
  methods: {
    toggleModalDetails() {
      this.showModalDetails = !this.showModalDetails;
    },
  },
};
</script>

<style scoped>
h2 {
  text-transform: uppercase;
  font-size: 28px;
}
.topBlue {
  background: #2e779a;
  border-radius: 25px;
  padding: 25px;
  color: #fff;
  font-size: 18px;
}
.topBlue h2 {
  color: #fff;
  font-size: 24px;
  text-transform: none;
}
</style>