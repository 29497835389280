<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal" style="position: relative">
      <a class="modalCloser" href="#" @click.self="closeModal">X</a>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["theme"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal {
  width: 70%;
  padding: 24px;
  margin: 20px auto;
  background: #fff;
  border-radius: 10px;
  z-index: 99999999;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  margin-left: -10%;
}
.modalCloser {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  border-radius: 50px;
  z-index: 9999;
  transition: 0.25s;
}
.modalCloser:hover {
  background: #2e769a;
  color: #fff;
}
</style>
