<template>
  <h1>Tap For Service</h1>
  <div class="row">
    <div class="col-1-3">
      <div class="row fadeUp topBlue">
        <p class="solutionChallengesHeader">The Highlights</p>
        <ul class="solutionChallengesBulletCybersecurity">
          <li>Service app installed onto device</li>
          <li>Quickest & easiest access to tech support</li>
          <li>Raise a service request with just a few taps</li>
          <li>Customisable interface</li>
        </ul>
      </div>
    </div>
    <div class="col-2-3">
      <video width="320" height="240" controls style="max-width: 640px;">
        <source src="/videos/tap-for-service.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="head3">How it works</div>
      <div>
        <img class="imgFull" src="/img/services/tap-for-service.png" />
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.head1,
.head2,
.head3 {
  color: #000;
  font-weight: 900;
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 48px;
  text-transform: uppercase;
}
.head3 {
  font-size: 28px;
  padding: 48px 0 0;
}
h2 {
  text-transform: uppercase;
  font-size: 28px;
}
.topBlue {
  background: #2e779a;
  border-radius: 25px;
  padding: 25px;
  color: #fff;
  font-size: 18px;
}
.topBlue h2 {
  color: #fff;
  font-size: 24px;
  text-transform: none;
}
video {
  border-radius: 25px;
}
.solutionChallengesHeader {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}
ul.solutionChallengesBulletCybersecurity {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.solutionChallengesBulletCybersecurity li {
  background: url("../../assets/img/bullet-blue-plus.png") top left no-repeat;
  padding: 16px 0 36px 72px;
}
</style>