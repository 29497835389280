import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home.vue";
import MWSPrintView from "../views/mws/Print.vue";
import MWSDigitalWorkplaceView from "../views/mws/DigitalWorkplace.vue";
import MWSITComputingView from "../views/mws/ITComputing.vue";
import MWSCommunicationView from "../views/mws/Communication.vue";
import MWSCybersecurityView from "../views/mws/Cybersecurity.vue";
import MWSNetworkView from "../views/mws/Network.vue";
import MWSDigitisationView from "../views/mws/Digitisation.vue";
import NotFoundView from "../views/NotFound.vue";
import ServiceSupportView from "../views/ServiceSupport.vue";
import ESGView from "../views/ESG.vue";
import EnvironmentView from "../views/esg/Environment.vue";
import CarbonNeutralityView from "../views/esg/CarbonNeutrality.vue";
import ESGProjectsView from "../views/esg/Projects.vue";
import SustainabilityTargetsView from "../views/esg/SustainabilityTargets.vue";
import PrintReleafView from "../views/esg/PrintReleaf.vue";
import AceLineView from "../views/esg/AceLine.vue";
import WhoWeWorkWithView from "../views/WhoWeWorkWith.vue";
import HPView from "../views/HP.vue";
import WhyApogeeView from "../views/WhyApogee.vue";
import OurPurposeView from "../views/OurPurpose.vue";
import SolutionView from "../views/solutions/Solutions.vue";
import SolutionDetailsView from "../views/solutions/SolutionDetails.vue";
import ExplorePackagesView from "../views/solutions/ExplorePackages.vue";
import TechAuditView from "../views/services/TechAudit.vue";
import AccountManagementView from "../views/services/AccountManagement.vue";
import OnlineSupportView from "../views/services/OnlineSupport.vue";
import TapForServiceView from "../views/services/TapForService.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/mws-print",
    name: "MWSPrint",
    component: MWSPrintView,
  },
  {
    path: "/mws-digital-workplace",
    name: "MWSDigitalWorkplace",
    component: MWSDigitalWorkplaceView,
  },
  {
    path: "/mws-it-computing",
    name: "MWSITComputing",
    component: MWSITComputingView,
  },
  {
    path: "/mws-communication",
    name: "MWSCommunication",
    component: MWSCommunicationView,
  },
  {
    path: "/mws-cybersecurity",
    name: "MWSCybersecurity",
    component: MWSCybersecurityView,
  },
  {
    path: "/mws-network",
    name: "MWSNetwork",
    component: MWSNetworkView,
  },
  {
    path: "/mws-digitisation",
    name: "MWSDigitisation",
    component: MWSDigitisationView,
  },
  {
    path: "/service-support",
    name: "ServiceSupport",
    component: ServiceSupportView,
  },
  {
    path: "/esg",
    name: "ESG",
    component: ESGView,
  },
  {
    path: "/environment",
    name: "Environment",
    component: EnvironmentView,
  },
  {
    path: "/carbon-neutrality",
    name: "CarbonNeutrality",
    component: CarbonNeutralityView,
  },
  {
    path: "/esg-projects",
    name: "ESGProjects",
    component: ESGProjectsView,
  },
  {
    path: "/sustainability-targets",
    name: "SustainabilityTargets",
    component: SustainabilityTargetsView,
  },
  {
    path: "/print-releaf",
    name: "PrintReleaf",
    component: PrintReleafView,
  },
  {
    path: "/ace-line",
    name: "AceLine",
    component: AceLineView,
  },
  {
    path: "/who-we-work-with",
    name: "WhoWeWorkWith",
    component: WhoWeWorkWithView,
  },
  {
    path: "/hp",
    name: "HP",
    component: HPView,
  },
  {
    path: "/why-apogee",
    name: "WhyApogee",
    component: WhyApogeeView,
  },
  {
    path: "/our-purpose",
    name: "OurPurpose",
    component: OurPurposeView,
  },
  {
    path: "/solutions",
    name: "Solutions",
    component: SolutionView,
  },
  {
    path: "/solutions/:id",
    name: "SolutionDetails",
    component: SolutionDetailsView,
    props: true,
  },
  {
    path: "/solutions/:id/explore-packages",
    name: "ExplorePackages",
    component: ExplorePackagesView,
    props: true,
  },
  {
    path: "/services/tech-audit",
    name: "Tech Audit",
    component: TechAuditView,
  },
  {
    path: "/services/online-support",
    name: "Online Support",
    component: OnlineSupportView,
  },
  {
    path: "/services/account-management",
    name: "Account Management",
    component: AccountManagementView,
  },
  {
    path: "/services/tap-for-service",
    name: "Tap For Service",
    component: TapForServiceView,
  },
  /* // redirect
  {
    path: '/all-solutions',
    redirect: '/solutions'
  }, */
  // Catch-all 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
