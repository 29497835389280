!<template>
  <img class="imgFull fadeDown" src="/img/content/mws-communications-collaboration.png" alt="Communications &amp; Collaboration" />
  <div style="clear:both;"></div>
  <div class="roundFloat text-center fadeDown delay-50 col-1-3 roundPadding">
    <a href="/solutions/11">
      <img
        class="imgRound"
        src="/img/photos/conference-room-stone-wall-video-meeting-round-table-1024x684.jpg"
        alt="Meeting Rooms"
        title="Meeting Rooms"
      />
      <div class="packageTitle">Meeting Rooms</div>
      <div class="packageDesc">
        Video conferencing and collaboration solutions.
      </div>
    </a>
  </div>
  <div class="roundFloat text-center fadeDown delay-50 col-1-3">
    <a href="/solutions/12">
      <img
        class="imgRound"
        src="/img/photos/woman-telecom-call-centre-headset-1024x638.jpg"
        alt="Telecoms"
        title="Telecoms"
      />
      <div class="packageTitle">Telecoms</div>
      <div class="packageDesc">
        Cloud-based VoIP packages to maximise the operations &amp; security of your communications.
      </div>
    </a>
  </div>
  <div class="roundFloat text-center fadeDown delay-50 col-1-3 roundPadding">
    <a href="#">
      <img
        class="imgRound"
        src="/img/photos/tottenham-hotspur-stadium.png"
        alt="Digital Signage"
        title="Digital Signage"
      />
      <div class="packageTitle">COMING SOON...</div>
      <div class="packageTitle">Digital Signage</div>
      <div class="packageDesc">
        Bring your environment to life, from digital point of sale, to event screens.
      </div>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media screen and (min-width: 1000px) {
  .roundPadding {
    margin-top: -140px;
  }
  img.imgFull {
    max-width: 55%;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageTitle {
    font-size: 1.25vw;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>