<template>
  <div class="col-3-4">
    <div class="roundFloat text-center fadeLeft delay-50 roundPadding">
      <a href="/solutions/14">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/endpoint-security.jpg"
          alt="Endpoint Security"
          title="Endpoint Security"
        />
        <div class="packageTitle">Endpoint Security</div>
        <div class="packageDesc">
          Bolster your company's cyber-resilience with HP Wolf Security.
        </div>
      </a>
    </div>
    <div class="roundFloat text-center fadeLeft delay-50">
      <a href="/solutions/15">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/cybersecurity-consultancy.png"
          alt="Cyber Security Consultancy"
          title="Cyber Security Consultancy"
        />
        <div class="packageTitle">Cyber Security Consultancy</div>
        <div class="packageDesc">
          Bring direction back to your cyber security strategy with Security Team
          as a Service (STaaS).
        </div>
      </a>
    </div>
  </div>
  <div class="col-1-4">
    <img
      class="imgDDS fadeRight"
      src="/img/content/mws-cybersecurity.png"
      alt="Cyber Security"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
img.imgWidth-200 {
  max-width: 260px;
}
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
img.imgDDS {
  display: none;
  visibility: hidden;
  width: 100%;
  max-width: 640px;
  height: auto;
}
@media screen and (min-width: 800px) {
  .roundPadding {
    margin-right: 140px;
  }
  .text-center {
    text-align: right;
  }
  .packageTitle {
    font-size: 24px;
    font-weight: 900;
    color: #000;
    padding: 100px 0 0 0;
  }
  .col-3-4 {
    position: absolute;
    left: 15%;
    max-width: 40%;
    margin-top: 5%;
  }
  .roundFloat {
    clear: both;
    position: centered;
    vertical-align: middle;
  }
  .roundFloat img {
    float: right;
    margin-left: 60px;
  }
  img.imgDDS {
    visibility: visible;
    display: initial;
    position: absolute;
    right: 8%;
    top: 0;
    width: 100%;
    max-width: 600px;
    height: auto;
  }
  img.imgFull {
    max-width: 55%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageTitle {
    font-size: 1.25vw;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>