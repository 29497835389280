<template>
  <h1>Why Apogee</h1>
  <h2>Work smarter and work safer,</h2>
  <h3>with Europe's leading provider of Managed Workplace Services</h3>
  <div class="row">
    <div style="margin-top: 60px">
      <div class="col-1-2" style="border-right: 2px solid #ccc">
        <ul class="blueChecks text-left fadeLeft delay-25">
          <li>Work anywhere securely</li>
          <li>Affordable &amp; innovative tech</li>
          <li>Automate repetitive admin tasks</li>
          <li>Improve employee retention</li>
          <li>Reduce impact on the environment</li>
        </ul>
      </div>
    </div>
    <div class="col-1-2 text-left fadeRight delay-25">
      <div class="row">
        <div class="col-1-3">
          <a href="https://uk.trustpilot.com/review/www.apogeecorp.com" target="_blank">
            <img
              src="/img/content/trustpilot-4.8-excellent-rating.png"
              class="imgAuto"
              alt="Trustpilot 4.8 excellent rating"
              title="Trustpilot 4.8 excellent rating"
            />
          </a>
        </div>
        <div class="col-2-3">
          <h4>Customer Excellence</h4>
          <p>Earning a Trustpilot <b>score of 4.8</b></p>
        </div>
      </div>
      <div class="row">
        <div class="col-1-3">
          <img
            src="/img/content/isp-ethics-in-sales.png"
            class="imgAuto"
            alt="ISP Ethics in Sales logo"
            title="ISP Ethics in Sales logo"
          />
        </div>
        <div class="col-2-3">
          <h4>Integrity, always.</h4>
          <p>
            Proudly part of the <b>ISP*,</b> delivering
            <b>ethical sales practices,</b> and <b>ISO accredited</b> for 9001,
            14001, &amp; 27001.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row text-left roundedBox fadeUp delay-50">
    <div class="col-1-3">
      <div class="col-2-3">
        <div class="titleNumber">Client satisfaction</div>
        <div class="boldNumber">92%</div>
      </div>
      <div class="col-1-3">
        <img
          src="/img/content/pink-ribbon-icon.png"
          class="imgAuto"
          alt="pink ribbon icon"
        />
      </div>
    </div>
    <div class="col-1-3">
      <div class="col-2-3">
        <div class="titleNumber">First time fix rate</div>
        <div class="boldNumber">94%</div>
      </div>
      <div class="col-1-3">
        <img
          src="/img/content/pink-tools-icon.png"
          class="imgAuto"
          alt="pink tools icon"
        />
      </div>
    </div>
    <div class="col-1-3">
      <div class="col-2-3">
        <div class="boldNumber">150,000+</div>
        <div class="smallNumber">Devices in field</div>
      </div>
      <div class="col-1-3">
        <img
          src="/img/content/pink-device-icon.png"
          class="imgAuto"
          alt="pink circle digital devices icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scope>
h2,
h3,
h4,
img.imgAuto,
ul {
  margin: 0;
  padding: 0;
}
h2,
h4 {
  color: #000;
}
.roundedBox {
  border-radius: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.6);
  display: flow-root;
  padding: 0;
  background-color: #fff;
}
img.imgAuto {
  width: 100%;
  height: auto;
}
.titleNumber {
  font-size: 1.5vw;
}
.boldNumber {
  font-size: 3.25vw;
}
.smallNumber {
  font-size: 18px;
  font-weight: 700;
}
.boldNumber,
.titleNumber,
h3,
h4 {
  font-weight: 900;
}
.boldNumber,
.smallNumber,
.titleNumber {
  color: #000;
  padding: 0;
  margin: 0;
}
h2,
ul.blueChecks li {
  font-weight: 900 !important;
}
h2 {
  font-size: 42px;
}
h3 {
  color: #009ade;
  font-size: 24px;
}
h4 {
  font-size: 1.75vw;
}
ul.blueChecks {
  list-style: none;
}
ul.blueChecks li {
  background: url("../assets/img/blue-check-circle.png") top left no-repeat;
  padding: 4px 0 36px 60px !important;
  font-size: 24px;
}
</style>
