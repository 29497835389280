<template>
  <h1>In Focus: ESG</h1>
  <div class="text-center roundedBox fadeUp delay-25 col-1-3">
    <a href="/environment">
      <img
        class="imgRound imgWidth-200"
        src="/img/photos/esg-road-through-trees.jpg"
        alt="Environmental"
        title="Environmental"
      />
      <div class="packageTitle">Environmental</div>
    </a>
  </div>
  <div class="text-center roundedBox fadeUp delay-50 col-1-3">
    <a href="#" @click="toggleModalSocial"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/esg-social.jpg"
        alt="Social"
        title="Social"
      />
      <div class="packageTitle">Social</div></a
    >
  </div>
  <div class="text-center roundedBox fadeUp delay-75 col-1-3">
    <a href="#" @click="toggleModalGovernance"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/esg-governance.jpg"
        alt="Governance"
        title="Governance"
      />
      <div class="packageTitle">Governance</div></a
    >
  </div>
  <div v-if="showModalSocial">
    <Modal theme="modalImage" @close="toggleModalSocial">
      <img
        class="imgFull"
        src="/img/how-it-works/esg-social.jpg"
        alt="Social"
        title="Social"
      />
    </Modal>
  </div>
  <div v-if="showModalGovernance">
    <Modal theme="modalImage" @close="toggleModalGovernance">
      <img
        class="imgFull"
        src="/img/how-it-works/esg-governance.jpg"
        alt="Governance"
        title="Governance"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      myStyle: {
        backgroundColor: "greenyellow",
      },
      color: "white",
      showModalGovernance: false,
      showModalSocial: false,
    };
  },
  methods: {
    toggleModalSocial() {
      this.showModalSocial = !this.showModalSocial;
    },
    toggleModalGovernance() {
      this.showModalGovernance = !this.showModalGovernance;
    },
  },
};
</script>

<style scoped>
body {
  background-image: none;
  background-color: #adff2f;
}
.packageTitle {
  font-size: 24px;
  color: #000;
  font-weight: 900;
  line-height: 3em;
  text-transform: uppercase;
}
</style>