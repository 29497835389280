<template>
  <img
    class="hpLogo"
    src="/img/icon/hp-logo-black-80px.png"
    alt="HP"
    title="HP"
  />
  <div class="header1 fadeIn">The enabler behind the technology</div>
  <div class="header2 fadeIn delay-25">
    Most Secure. Most Innovative. Most Sustainable.
  </div>
  <div class="text-center fadeUp delay-25 col-1-3">
    <a href="#" @click="toggleModalMeanForYou">
      <img
        class="imgRound imgWidth-200"
        src="/img/photos/hp-mean-for-you.jpg"
        alt="What does this mean for you?"
        title="What does this mean for you?"
    /></a>
    <div class="packageTitle">What does this mean<br />for you?</div>
    <button @click="toggleModalMeanForYou">Learn More</button>
  </div>
  <div class="text-center fadeUp delay-50 col-1-3">
    <a href="#" @click="toggleModalInnovative"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/hp-innovative.png"
        alt="Most innovative technology"
        title="Most innovative technology"
    /></a>
    <div class="packageTitle">Most innovative<br />technology</div>
    <button @click="toggleModalInnovative">Learn More</button>
  </div>
  <div class="text-center fadeUp delay-75 col-1-3">
    <a href="#" @click="toggleModalIDC"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/hp-idc.png"
        alt="Named leader in IDC MarketScape Worldwide Security Solutions &amp; Services"
        title="Named leader in IDC MarketScape Worldwide Security Solutions &amp; Services"
    /></a>
    <div class="packageTitle">
      Named leader in IDC MarketScape Worldwide Security Solutions &amp;
      Services
    </div>
    <button @click="toggleModalIDC">Learn More</button>
  </div>
  <div class="header3 fadeUp delay-100">
    Sustainable, efficient, cost-effective Managed Workplace Services, based on
    technology that's built to last.
  </div>
  <p class="buttonBlue fadeUp delay-125">Proud to be brand agnostic</p>
  <div v-if="showModalMeanForYou">
    <Modal theme="modalImage" @close="toggleModalMeanForYou">
      <div class="row">
        <div class="col-1-2">
          <img
            class="imgRound"
            src="/img/photos/hp-mean-for-you.jpg"
            alt="What does this mean for you?"
            title="What does this mean for you?"
          />
        </div>
        <div class="col-1-2 text-left">
          <h3>What does this mean for you?</h3>
          <p>
            Sustainable, efficient, cost-effective Managed Workplace Services,
            based on technology that's built to last.
          </p>
          <p>
            That's how we help your business to <b>work smarter, and safer</b> -
            with the assurance of quality that comes with being an independent
            HP subsidiary.
          </p>
          <p>
            When it comes to your business technology, you can't afford to take
            risks. With industry-leading cyber security, proactive
            sustainability, and cost-effective support all sourced from HP's
            fully compliant and ethical supply chain.
          </p>
        </div>
      </div>
    </Modal>
  </div>
  <div v-if="showModalInnovative">
    <Modal theme="modalImage" @close="toggleModalInnovative">
      <div class="row">
        <div class="col-1-2">
          <img
            class="imgRound"
            src="/img/photos/hp-innovative.png"
            alt="Most innovative technology"
            title="Most innovative technology"
          />
        </div>
        <div
          class="col-1-2 text-left"
          style="display: inline-block; vertical-align: middle"
        >
          <h3>Most Innovative</h3>
          <p>
            Give your business a competitive edge, with technology from a market
            leader for innovation. While you can't always predict the challenges
            of a rapidly evolving tech landscape, HP's constant reinvention
            enables you to stay agile and effective for what's around the
            corner.
          </p>
        </div>
      </div>
    </Modal>
  </div>
  <div v-if="showModalIDC">
    <Modal theme="modalImage" @close="toggleModalIDC">
      <div class="row">
        <div class="col-1-2">
          <img
            style="width: 100%; height: auto"
            src="/img/how-it-works/hp-idc.png"
            alt="IDC"
            title="IDC"
          />
        </div>
        <div class="col-1-2 text-left">
          <p class="pQuote">
            <em
              >"Over the past couple of years, HP Inc. has led the charge when
              it comes to raising awareness for securing the print
              infrastructure. The now famous Wolf campaign was very successful
              in helping HP Inc. drive thought leadership while providing a
              mechanism for engaging with customers in deeper conversations on
              the need for print security."</em
            >
          </p>
          <div class="horizontalRow"></div>
          <p class="text-center">
            <b>IDC MarketScape: Worldwide Security Solutions and Services</b>
          </p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      showModalMeanForYou: false,
      showModalInnovative: false,
      showModalIDC: false,
    };
  },
  methods: {
    toggleModalMeanForYou() {
      this.showModalMeanForYou = !this.showModalMeanForYou;
    },
    toggleModalInnovative() {
      this.showModalInnovative = !this.showModalInnovative;
    },
    toggleModalIDC() {
      this.showModalIDC = !this.showModalIDC;
    },
  },
};
</script>

<style scoped>
.packageTitle,
p.buttonBlue,
p.pQuote {
  font-size: 18px;
}
.horizontalRow {
  height: 2px;
  background-color: #000;
  width: 60%;
  margin: 0 auto;
}
.header1,
.header2,
.header3,
.packageTitle {
  color: #000;
  font-weight: 900;
}
body {
  background-image: none;
  background-color: #adff2f;
}
.header1 {
  font-size: 36px;
}
.header2,
.header3 {
  font-size: 24px;
}
.header3 {
  max-width: 800px;
  margin: 0 auto;
  clear: both;
}
.hpLogo {
  padding: 24px;
}
button {
  margin: 24px 0;
}
p.buttonBlue {
  text-transform: uppercase;
  margin: 24px auto;
  max-width: 300px;
  color: #fff;
  font-weight: 700;
  border-radius: 25px;
  padding: 8px 48px;
  background-color: #009ebd;
  box-shadow: 0 2px 8px #666;
}
</style>
