!<template>
  <div class="col-1-4">
    <img
      class="imgDDS fadeLeft"
      src="/img/content/mws-document-digitisation.png"
      alt="Document Digitisation &amp; Scanning"
    />
  </div>
  <div class="col-3-4">
    <div class="roundFloat text-center fadeRight delay-50">
      <a href="/solutions/18">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/document-management-in-the-workplace-1.jpg"
          alt="Digital Capture"
          title="Digital Capture"
        />
        <div class="packageTitle">Digital Capture</div>
        <div class="packageDesc">
          An effective way of ensuring paper-based documents are digitised to be
          secure and searchable.
        </div>
      </a>
    </div>
    <div class="roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/19">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/digital-mail-2-1024x683.jpg"
          alt="Inbound Mail"
          title="Inbound Mail"
        />
        <div class="packageTitle">Digital Mailroom</div>
        <div class="packageDesc">
          Access your post from anywhere - secure scanning process that converts
          incoming paper mail into PDF for digital delivery.
        </div>
      </a>
    </div>
    <div class="roundFloat text-center fadeRight delay-50">
      <a href="/solutions/20">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/scanning.jpg"
          alt="Bulk Scanning"
          title="Bulk Scanning"
        />
        <div class="packageTitle">Bulk Scanning</div>
        <div class="packageDesc">
          For large volumes of paper-based documentation - from archives to
          receipts.
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
img.imgWidth-200 {max-width: 220px;}
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
img.imgDDS {
  display: none;
  visibility: hidden;
  height: 100%;
  max-height: 900px;
  width: auto;
}
@media screen and (min-width: 800px) {
  .roundPadding {
    margin-left: 60px;
  }
  .text-center {
    text-align: left;
  }
  .packageTitle {
    font-size: 24px;
    font-weight: 900;
    color: #000;
    padding: 60px 0 0 0;
  }
  .col-3-4 {
    position: absolute;
    left: 35%;
    max-width: 50%;
    margin-top: 5%;
  }
  .roundFloat {
    clear: both;
    position: centered;
    vertical-align: middle;
  }
  .roundFloat img {
    float: left;
    margin-right: 60px;
  }
  img.imgDDS {
    visibility: visible;
    display: initial;
    position: absolute;
    left: 8%;
    top: 0;
    height: 100%;
    max-height: 900px;
    width: auto;
  }
  img.imgFull {
    max-width: 55%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageTitle {
    font-size: 1.25vw;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>