<template>
  <h1>Account Management</h1>
  <div class="row fadeUp delay">
    <h2>Audit Methodology</h2>
    <img
          class="imgFull"
          src="/img/services/account-management.png"
          alt="Account Management"
          title="Account Management"
      />
  </div>
  <div class="row">
    <div class="col-1-2 fadeUp delay-50">
      <div>
        <img
          class="imgFull"
          src="/img/services/account-management-integrity.png"
          alt="Account Management"
          title="Account Management"
      />
      </div>
    </div>
    <div class="col-1-2">
      <button class="buttonPink fadeUp delay-75" @click="toggleModalTimeline">Contract Timeline Example</button>
    </div>
  </div>
  <div v-if="showModalTimeline">
    <Modal theme="modalImage" @close="toggleModalTimeline">
      <img
        class="imgFull"
        :src="'/img/services/timeline.png'"
        alt="Timeline"
        title="Timeline"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      showModalTimeline: false,
    };
  },
  methods: {
    toggleModalTimeline() {
      this.showModalTimeline = !this.showModalTimeline;
    },
  },
};
</script>


<style scoped>
.head1,
.head2,
.head3 {
  color: #000;
  font-weight: 900;
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 48px;
  text-transform: uppercase;
}
.head3 {
  font-size: 28px;
}
h2 {
  text-transform: uppercase;
  font-size: 28px;
}
.topBlue {
  background: #2e779a;
  border-radius: 25px;
  padding: 25px;
  color: #fff;
  font-size: 18px;
}
.topBlue h2 {
  color: #fff;
  font-size: 24px;
  text-transform: none;
}
video {
  border-radius: 25px;
}
</style>