!<template>
  <div class="col-1-4">
    <img
      class="imgDDS fadeLeft"
      src="/img/content/mws-it-computing.png"
      alt="IT and Computing"
    />
  </div>
  <div class="col-3-4">
    <div class="roundFloat text-center fadeRight delay-50 roundPadding2">
      <a href="/solutions/8">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/mds.jpg"
          alt="MDS - Managed Desktop Services"
          title="MDS - Managed Desktop Services"
        />
        <div class="packageTitle">MDS - Managed Desktop Services</div>
        <div class="packageDesc">
          Fully supported IT devices on a user based subscription model.
        </div>
      </a>
    </div>
    <div class="roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/9">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/era.jpg"
          alt="Technology Audit: Estate Review &amp; Analysis (ERA)"
          title="Technology Audit: Estate Review &amp; Analysis (ERA)"
        />
        <div class="packageTitle">Technology Audit: Estate Review &amp; Analysis (ERA)</div>
        <div class="packageDesc">
          Technology audit on the status and performance of your entire IT infrastructure.
        </div>
      </a>
    </div>
    <div class="roundFloat text-center fadeRight delay-50">
      <a href="/solutions/10">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/buy-back.jpg"
          alt="Buy-Back"
          title="Buy-Back"
        />
        <div class="packageTitle">Buy-Back</div>
        <div class="packageDesc">
          Offset the cost of new IT devices by trading in your current fleet.
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
img.imgWidth-200 {max-width: 220px;}
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
img.imgDDS {
  display: none;
  visibility: hidden;
  height: 100%;
  max-height: 900px;
  width: auto;
}
@media screen and (min-width: 800px) {
  .roundPadding {
    margin-left: 80px;
  }
    .roundPadding2 {
    margin-left: 160px;
  }
  .text-center {
    text-align: left;
  }
  .packageTitle {
    font-size: 24px;
    font-weight: 900;
    color: #000;
    padding: 60px 0 0 0;
  }
  .col-3-4 {
    position: absolute;
    left: 35%;
    max-width: 50%;
    margin-top: 5%;
  }
  .roundFloat {
    clear: both;
    position: centered;
    vertical-align: middle;
  }
  .roundFloat img {
    float: left;
    margin-right: 60px;
  }
  img.imgDDS {
    visibility: visible;
    display: initial;
    position: absolute;
    left: 6%;
    top: 0;
    height: 100%;
    max-height: 700px;
    width: auto;
  }
  img.imgFull {
    max-width: 55%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageTitle {
    font-size: 1.25vw;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>