<template>
  <div v-if="job">
    <div v-if="job.templateSingle">
      <h1>{{ job.title }}</h1>
      <div class="templateSingleContainer">
        <div class="templateSingleHalf">
          <div>
            <div class="templateSingleHeader" v-html="job.header"></div>
            <div class="templateSingleBreak"></div>
            <div class="templateSingleQuote" v-html="job.quote"></div>
          </div>
          <div class="templateSingleDetails">
            <h2>THE DETAIL:</h2>
            <div v-html="job.details"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>Solution Focus: {{ job.title }}</h1>
      <div class="solutionChallenges fadeUp delay-25">
        <div v-if="job.blueBullets !== 'true'">
          <div v-html="job.desc" class="solutionChallengesDesc"></div>
          <p class="solutionChallengesHeader">Challenges</p>
          <ul class="solutionChallengesBullet">
            <li v-for="bullet in job.bullets" :key="bullet.bulletId">
              {{ bullet.bulletDesc }}
            </li>
          </ul>
        </div>
        <div v-else>
          <p class="solutionChallengesHeader">The Highlights</p>
          <ul class="solutionChallengesBulletCybersecurity">
            <li v-for="bullet in job.bullets" :key="bullet.bulletId">
              {{ bullet.bulletDesc }}
            </li>
          </ul>
        </div>
      </div>
      <div class="solutionImpact fadeUp delay-50">
        <div class="contentImg" v-if="job.contentImg">
          <img
            class="contentImage"
            :src="'/img/content/' + job.contentImg + '.png'"
          />
        </div>
        <div v-else>
          <p class="solutionImpactHeader fadeUp delay-50">Client Impact</p>
        </div>

        <div class="clientRow">
          <div
            v-for="client in job.clients"
            :key="client.clientId"
            class="solutionImpactClient fadeUp delay-75"
          >
            <a href="#" @click="toggleModalClient(client.clientId)">
              <img
                :src="'/img/logos/' + client.clientSlug + '.png'"
                :alt="client.clientName + ' logo'"
                :title="client.clientName"
              />
            </a>
            <button @click="toggleModalClient(client.clientId)">
              Learn More
            </button>
          </div>
        </div>

        <div class="buttonRow">
          <div class="solutionImpactClient fadeUp delay-100">
            <a href="#" @click="toggleModalVideo">
              <div class="solutionImpactBox">
                <img src="../../assets/img/play-icon.png" />
              </div>
            </a>
            <div class="solutionImpactText">Explainer Video</div>
          </div>
          <div v-if="job.videoTwoTitle">
            <div class="solutionImpactClient fadeUp delay-100">
              <a href="#" @click="toggleModalVideo2">
                <div class="solutionImpactBox">
                  <img src="../../assets/img/play-icon.png" />
                </div>
              </a>
              <div class="solutionImpactText">{{ job.videoTwoTitle }}</div>
            </div>
          </div>
          <div class="solutionImpactClient fadeUp delay-100">
            <a href="#" @click="toggleModalWorks">
              <div class="solutionImpactBox">
                <div v-if="job.hiwImg">
                  <img :src="'/img/icon/' + job.hiwImg + '.png'" />
                </div>
                <div v-else>
                  <img :src="'/img/icon/how-it-works-icon.png'" />
                </div>
              </div>
            </a>
            <div class="solutionImpactText">
              <div v-if="job.hiwTitle">{{ job.hiwTitle }}</div>
              <div v-else>How It Works:<br />{{ job.title }}</div>
            </div>
          </div>
          <div v-if="job.hiwLinkTitle">
            <div class="solutionImpactClient fadeUp delay-100">
              <a :href="job.id + '/' + job.hiwLinkUrl">
                <div class="solutionImpactBox">
                  <div v-if="job.hiwLinkImg">
                    <img :src="'/img/icon/' + job.hiwLinkImg + '.png'" />
                  </div>
                  <div v-else>
                    <img :src="'/img/icon/how-it-works-icon.png'" />
                  </div>
                </div>
              </a>
              <div class="solutionImpactText">
                <div>{{ job.hiwLinkTitle }}</div>
              </div>
            </div>
          </div>
          <div v-if="job.hiw2Title">
            <div class="solutionImpactClient fadeUp delay-100">
              <a href="#" @click="toggleModalWorks2">
                <div class="solutionImpactBox">
                  <div v-if="job.hiw2Img">
                    <img :src="'/img/icon/' + job.hiw2Img + '.png'" />
                  </div>
                  <div v-else>
                    <img :src="'/img/icon/how-it-works-icon.png'" />
                  </div>
                </div>
              </a>
              <div class="solutionImpactText">
                <div>{{ job.hiw2Title }}</div>
              </div>
            </div>
          </div>
          <div v-if="job.hiw3Title">
            <div class="solutionImpactClient fadeUp delay-100">
              <a href="#" @click="toggleModalWorks3">
                <div class="solutionImpactBox">
                  <img :src="'/img/icon/how-it-works-icon.png'" />
                </div>
              </a>
              <div class="solutionImpactText">
                <div>How It Works:<br />{{ job.hiw3Title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModalVideo">
        <Modal theme="modalVideo" @close="toggleModalVideo">
          <video width="320" height="240" controls autoplay>
            <source :src="'/videos/' + job.slug + '.mp4'" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      </div>
      <div v-if="showModalVideo2">
        <Modal theme="modalVideo2" @close="toggleModalVideo2">
          <video width="320" height="240" controls autoplay>
            <source
              :src="'/videos/' + job.videoTwoUrl + '.mp4'"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Modal>
      </div>
      <div v-if="showModalWorks">
        <Modal theme="modalImage" @close="toggleModalWorks">
          <img
            class="imgFull"
            :src="'/img/how-it-works/' + job.slug + '.png'"
            :alt="job.title"
            :title="job.title"
          />
        </Modal>
      </div>
      <div v-if="showModalWorks2">
        <Modal theme="modalImage" @close="toggleModalWorks2">
          <img
            class="imgFull"
            :src="'/img/how-it-works/' + job.hiw2Slug + '.png'"
            :alt="job.hiw2Title"
            :title="job.hiw2Title"
          />
        </Modal>
      </div>
      <div v-if="showModalWorks3">
        <Modal theme="modalImage" @close="toggleModalWorks3">
          <img
            class="imgFull"
            :src="'/img/how-it-works/' + job.hiw3Slug + '.png'"
            :alt="job.hiw3Title"
            :title="job.hiw3Title"
          />
        </Modal>
      </div>
      <div v-if="showModalClient">
        <div class="clientModalConstrainer">
          <Modal theme="modalClient" @close="toggleModalClient">
          <div>
            <div>
              <h2>{{ this.client.clientIndustry }}</h2>
            </div>
            <div>
              <div>
                <img
                  class="imgRound"
                  :src="'/img/photos/' + this.client.clientSlug + '.jpg'"
                  :alt="client.clientName"
                  :title="client.clientName"
                />
              </div>
              <div>
                <img
                  class="imgLogo"
                  :src="'/img/logos/' + this.client.clientSlug + '.png'"
                  :alt="client.clientName"
                  :title="client.clientName"
                />
              </div>
            </div>
            <ul>
              <li
                v-for="clientbullet in this.client.clientBullets"
                :key="clientbullet.clientBulletId"
              >
                {{ clientbullet.clientBulletText }}
              </li>
            </ul>
          </div>
        </Modal>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading solution details...</p>
  </div>
</template>

<script>
var client = null;
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  props: ["id"],
  data() {
    return {
      color: "white",
      job: null,
      showModalVideo: false,
      showModalVideo2: false,
      showModalWorks: false,
      showModalWorks2: false,
      showModalWorks3: false,
      showModalClient: false,
    };
  },
  mounted() {
    fetch("/data/db.json")
        .then((res) => res.json())
        .then((data) => (this.job = data['jobs'][this.id]))
        .catch((err) => console.log(err.message));
  },
  methods: {
    toggleModalVideo() {
      this.showModalVideo = !this.showModalVideo;
    },
    toggleModalVideo2() {
      this.showModalVideo2 = !this.showModalVideo2;
    },
    toggleModalWorks() {
      this.showModalWorks = !this.showModalWorks;
    },
    toggleModalWorks2() {
      this.showModalWorks2 = !this.showModalWorks2;
    },
    toggleModalWorks3() {
      this.showModalWorks3 = !this.showModalWorks3;
    },
    toggleModalClient(id) {
      this.client = this.job.clients[id - 1];
      this.showModalClient = !this.showModalClient;
    },
  },
};
</script>

<style>
.clientModalConstrainer .modal {
  max-width: 360px;
  padding: 72px 24px;
}
.clientModalConstrainer .modal img.imgLogo {
  max-width: 140px;
  height: auto;
}
.clientModalConstrainer .modal ul {
  padding: 14px;
}
.clientModalConstrainer .modal ul li {
  padding: 12px 0;
  font-size: 13px;
}
.buttonRow,
.clientRow,
.templateSingleHalf {
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
img.contentImage {
  width: 100%;
  height: auto;
}
.contentImg {
  padding: 32px 0;
}
.clientModalConstrainer h2,
p.solutionImpactHeader {
  text-transform: uppercase;
  font-weight: 700;
}
.clientModalConstrainer h2 {
  font-size: 32px;
  color: #ccc;
  font-weight: 900;
  position: absolute;
  left: 0;
  width: 200px;
  text-align: center;
  transform: rotate(270deg) translateX(0%) translateY(-300%);
  transform-origin: 100% 100%;
}
body {
  background-color: v-bind(color);
}
.solutionChallenges {
  display: block;
  border-radius: 25px;
  background-color: #2e769a;
  width: 24%;
  padding: 24px;
  margin: 2%;
  color: #fff;
  text-align: left;
  float: left;
  box-shadow: 2px 2px 8px #999;
}
.solutionChallengesDesc {
  font-size: 24px;
  font-style: italic;
  line-height: 1.2em;
  display: inline-block;
  padding-bottom: 24px;
  border-bottom: 1px solid #80b5d0;
}
.solutionChallengesHeader {
  font-size: 24px;
  font-weight: 700;
}
ul.solutionChallengesBullet,
ul.solutionChallengesBulletCybersecurity {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.solutionChallengesBullet li {
  background: url("../../assets/img/liYellowExclamation.png") top left
    no-repeat;
  padding: 0 0 36px 60px;
}
ul.solutionChallengesBulletCybersecurity li {
  background: url("../../assets/img/bullet-blue-plus.png") top left no-repeat;
  padding: 16px 0 36px 72px;
}
.solutionImpact {
  display: block;
  float: left;
  padding: 0 24px;
  margin: 0 2%;
  width: 55%;
}
p.solutionImpactHeader {
  color: #000;
  font-size: 36px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
p.solutionImpactHeader:after,
p.solutionImpactHeader:before {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 47%;
  height: 2px;
  content: "";
  background-color: #000;
}
p.solutionImpactHeader:before {
  margin-left: -50%;
  text-align: right;
  margin-right: 18px;
}
p.solutionImpactHeader:after {
  margin-left: 3%;
}
.buttonRow,
.clientRow {
  display: grid;
}
.solutionImpactClient {
  text-align: center;
  margin-bottom: 120px;
}
.solutionImpactClient img {
  object-fit: contain;
  height: 140px;
  width: 280px;
  padding-bottom: 16px;
}
.solutionImpactBox,
.solutionImpactBox img {
  width: 80px;
  height: 80px;
}
.solutionImpactClient button,
.solutionImpactClient img {
  text-align: center;
  display: block;
  clear: both;
  margin: 0 auto;
}
.solutionImpactBox {
  display: block;
  background: #31a3d0;
  background: linear-gradient(90deg, #31a3d0 0, #015f88 100%);
  box-shadow: 0 2px 8px #666;
  color: #fff;
  font-size: 24px;
  padding: 0;
  margin: 0 auto;
  border-radius: 25px;
  transition: 0.25s;
}
.solutionImpactBox:hover {
  filter: brightness(150%);
}
.solutionImpactText {
  color: #000;
  font-weight: 900;
  font-size: 18px;
  margin: 12px auto;
  text-align: center;
}
.templateSingleContainer {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}
.templateSingleHeader,
.templateSingleQuote {
  color: #fff;
  line-height: 1.2em;
  margin: 0;
  padding: 24px;
}
.templateSingleQuote {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
}
.templateSingleDetails {
  color: #000;
  line-height: 1.2em;
  margin: 0;
  padding: 36px 48px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 #00000063;
  border-radius: 25px;
  font-size: 18px;
}
.templateSingleHeader {
  font-size: 36px;
  font-weight: 900 !important;
}
.templateSingleHalf {
  text-align: left;
  display: grid;
}
.templateSingleBreak {
  width: 75%;
  height: 5px;
  margin: 24px 0 24px 28px;
  background-color: #fff;
}
</style>
