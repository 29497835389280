<template>
  <p class="subheader">HP aims to be the</p>
  <h1>Most Sustainable Tech Company by 2025</h1>
  <div class="row mainContent">
    <div class="col-1-3">
      <div class="solutionChallenges fadeUp">
        <p>
          <i
            >"We are keeping millions of plastic bottles from ever reaching our
            oceans, converting them into sustainable products, and creating new
            opportunities for the local community."</i
          >
        </p>
        <p class="quoteAttrib">
          <strong>Ellen Jackowski,</strong><br />Chief Impact Officer and Head
          of Social Impact, HP
        </p>
      </div>
    </div>
    <div class="col-1-3 fadeUp delay-25">
      <img
        class="imgAuto"
        src="/img/content/environmental-content-900px.png"
        alt="Plastic bottle recycling and print ink and toner cartridges recycling goals"
        title="Plastic bottle recycling and print ink and toner cartridges recycling goals"
      />
    </div>
    <div class="col-1-3 fadeRight delay-50">
      <div class="floatImage">
        <a href="/print-releaf">
          <img
            class="imgRound imgWidth"
            src="/img/photos/img_circle_printreleaf-300px.png"
            alt="Offset your print with PrintReleaf"
            title="Offset your print with PrintReleaf"
        /></a>
        <div class="packageTitle">Offset your print with PrintReleaf</div>
      </div>
      <div class="floatImage">
        <a href="/ace-line">
          <img
            class="imgRound imgWidth"
            src="/img/photos/ace-line-300px.png"
            alt="Enter the circular economy with the ACE Line"
            title="Enter the circular economy with the ACE Line"
        /></a>
        <div class="packageTitle">
          Enter the circular economy with the ACE Line
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-1-3">
      <a href="/carbon-neutrality">
        <button class="buttonBlue fadeUp delay-125">Carbon Neutrality</button>
      </a>
    </div>
    <div class="col-1-3">
      <a href="/esg-projects">
        <button class="buttonBlue fadeUp delay-125">Projects</button>
      </a>
    </div>
    <div class="col-1-3">
      <a href="/sustainability-targets">
        <button class="buttonBlue fadeUp delay-125">
          Our Sustainability Targets
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.subheader {
  font-size: 28px;
  margin-bottom: -24px;
}
.packageTitle {
  font-weight: 700;
}
.mainContent .col-1-3:first-child {
  width: 20%;
}
.mainContent .col-1-3:nth-child(2) {
  width: 52%;
}
.mainContent .col-1-3:nth-child(3) {
  width: 15%;
  border-left: 2px solid #7db02b;
}
.solutionChallenges {
  background-color: rgba(125, 176, 43, 0.8);
  width: 90%;
}
.greenBox p {
  font-size: 20px;
  text-align: left;
  color: #fff;
}
.greenBox p.quoteAttrib {
  font-size: 14px;
}
button.buttonBlue {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .col-1-3,
  .col-1-4,
  .col-2-3 {
    width: 100% !important;
  }
  .mainContent .col-1-3:nth-child(3) {
    border-left: none;
  }
}
</style>