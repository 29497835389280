<template>
  <div class="home">
    <h1>Service Overview:<br />Click to Expand</h1>
    <div class="hello">
      <img
        class="fadeUp"
        src="../assets/img/mws_wheel.png"
        width="1842"
        height="919"
        alt="Apogee Managed Workplace Services"
        title="Apogee Managed Workplace Services"
        usemap="#homemap"
      />
    </div>
  </div>
  <map name="homemap">
    <area
      target=""
      alt="Why HP"
      title="Why HP"
      href="/hp"
      coords="1094,266,39"
      shape="circle"
    />
    <area
      target=""
      alt="Service Support"
      title="Service Support"
      href="/service-support"
      coords="928,657,71"
      shape="circle"
    />
    <area
      target=""
      alt="Print"
      title="Print"
      href="/mws-print"
      coords="271,182,324,155,386,142,442,142,504,158,559,179,491,299,476,290,456,329,375,329,352,293,339,297"
      shape="poly"
    />
    <area
      target=""
      alt="Digital Workplace"
      title="Digital Workplace"
      href="/mws-digital-workplace"
      coords="568,184,612,216,653,259,676,301,699,350,713,436,575,438,575,421,534,421,490,350,511,316,498,304"
      shape="poly"
    />
    <area
      target=""
      alt="IT &amp; Computing"
      title="IT &amp; Computing"
      href="/mws-it-computing"
      coords="712,447,703,512,686,571,666,605,632,652,568,698,500,581,513,568,492,535,534,462,575,464,575,446"
      shape="poly"
    />
    <area
      target=""
      alt="Communication &amp; Collaboration"
      title="Communication &amp; Collaboration"
      href="/mws-communication"
      coords="456,555,476,590,490,584,559,704,505,730,453,740,399,743,331,730,269,704,341,584,355,592,373,558"
      shape="poly"
    />
    <area
      target=""
      alt="Cybersecurity"
      title="Cybersecurity"
      href="/mws-cybersecurity"
      coords="337,535,315,571,331,581,261,698,204,656,171,616,149,584,123,519,113,446,251,446,251,462,295,462"
      shape="poly"
    />
    <area
      target=""
      alt="Network &amp; Infrastructure"
      title="Network &amp; Infrastructure"
      href="/mws-network"
      coords="259,185,329,304,315,316,337,348,295,423,256,421,253,436,114,438,127,353,145,307,165,272,204,226"
      shape="poly"
    />
    <area
      target=""
      alt="Digital Print &amp; Reprographics"
      title="Digital Print &amp; Reprographics"
      href="/solutions/17"
      coords="1641,222,1545,321,1533,310,1504,336,1434,313,1363,339,1338,310,1323,319,1226,222,1281,179,1345,150,1408,137,1454,136,1506,144,1577,173"
      shape="poly"
    />
    <area
      target=""
      alt="Document Digitisation &amp; Scanning"
      title="Document Digitisation &amp; Scanning"
      href="/mws-digitisation"
      coords="1651,647,1551,548,1561,533,1535,509,1559,437,1537,369,1561,341,1550,328,1649,229,1686,276,1709,320,1727,377,1734,444,1722,512,1699,579"
      shape="poly"
    />
    <area
      target=""
      alt="Graphic Design &amp; Creative Print"
      title="Graphic Design &amp; Creative Print"
      href="/solutions/21"
      coords="1226,652,1327,553,1336,564,1363,535,1437,561,1506,535,1532,563,1545,555,1642,652,1603,685,1551,714,1491,734,1419,737,1351,725,1284,701"
      shape="poly"
    />
    <area
      target=""
      alt="Project &amp; People Resources"
      title="Project &amp; People Resources"
      href="/solutions/22"
      coords="1333,508,1306,536,1317,547,1218,646,1169,586,1143,518,1133,449,1135,394,1151,334,1177,282,1216,228,1317,327,1304,342,1333,367,1310,433"
      shape="poly"
    />
  </map>
</template>

<script></script>

<style scoped>
.hello {
  margin: 0 auto;
  width: 90%;
}
img[usemap] {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto;
}
h1 {
  margin-top: 36px;
}
</style>
