<template>
  <h1>Service Overview:<br>Click to Expand</h1>
  <div v-for="job in jobs" :key="job.id" class="job">
    <router-link :to="{ name: 'SolutionDetails', params: { id: job.id } }">
      <p>{{ job.title }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [],
    };
  },
  mounted() {
    fetch("/data/db.json")
      .then((res) => res.json())
      .then((data) => (this.jobs = data['jobs']))
      .catch((err) => console.log(err.message));
  },
};
</script>

<style>
</style>
