<template>
  <div class="bodyNetwork">
    <h1>Network Services</h1>
    <div class="templateSingleContainer">
      <div class="templateSingleHalf">
        <div>
          <div class="templateSingleHeader fadeIn">
            Service provider Argon Business Systems Limited has been acquired
            by Apogee Corporation.
          </div>
          <div class="templateSingleBreak fadeIn"></div>
          <div class="templateSingleQuote fadeIn delay-50">
            <i
              >"Argon's reputation will enhance Apogee's coverage and
              capabilities to support its customers with managed IT services,
              while forming part of our ongoing growth strategy, as we aim to
              create the perfect combination of secure and flexible solutions
              for our customers' workforce."</i
            >
          </div>
        </div>
        <div class="templateSingleDetails fadeRight delay-100">
          <h2>THE DETAIL:</h2>
          <div>
            <p>
              The company was founded in 1983 and predominantly provides
              IT-based services including managed print services and other
              professional services capabilities.
            </p>
            <p>
              Aurelio Maruggi, the former chief executive of Apogee, said:
              <i
                >"This acquisition helps strengthen Apogee's position as a
                leader in managed workplace services, both in the UK and, as we
                continue to drive growth, in Continental Europe."</i
              >
            </p>
            <p>
              Argon's chief executive Lain Fairbairn said:
              <i
                >"This deal will enable the ongoing growth and development of
                the Argon team, expanding our range of managed IT services into
                the Apogee customer base across the UK and Ireland.</i
              >
            </p>
            <p>
              <i
                >"It enhances the strength of the business for the future, and
                is recognition of the excellent service provided by the Argon
                team.</i
              >
            </p>
            <p>
              <i
                >"Working with Apogee we will be able to extend the solutions
                delivered and the value we can offer to our wide-ranging
                customer base."</i
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  color: #fff;
}
.bodyNetwork {
  margin-top: 24px;
  padding: 24px 0 64px;
  border-radius: 25px;
  background: url("/public/img/photos/network-background.jpg") center center
    no-repeat;
  background-size: cover;
}
</style>