<template>
  <h1>In Focus: ESG</h1>
  <div class="text-center roundedBox fadeUp delay-25 col-1-3">
    <a href="#" @click="toggleModalBorneo">
      <img
        class="imgRound imgWidth-200"
        src="/img/photos/borneo.jpg"
        alt="Rimba Raya Biodiversity Reserve Borneo"
        title="Rimba Raya Biodiversity Reserve Borneo"
      />
      <div class="packageTitle">
        Rimba Raya Biodiversity Reserve,<br />Borneo
      </div>
    </a>
  </div>
  <div class="text-center roundedBox fadeUp delay-50 col-1-3">
    <a href="#" @click="toggleModalUS"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/united-states.jpg"
        alt="North American Grasslands United States"
        title="North American Grasslands United States"
      />
      <div class="packageTitle">
        North American Grasslands,<br />United States
      </div></a
    >
  </div>
  <div class="text-center roundedBox fadeUp delay-75 col-1-3">
    <a href="#" @click="toggleModalMalawi"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/malawi.jpg"
        alt="Kulera REDD+ and Cookstoves Malawi"
        title="Kulera REDD+ and Cookstoves Malawi"
      />
      <div class="packageTitle">
        Kulera REDD+ and Cookstoves,<br />Malawi
      </div></a
    >
  </div>
  <div class="text-center roundedBox fadeUp delay-100 col-1-3">
    <a href="#" @click="toggleModalUK"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/uk.jpg"
        alt="Forest Creation UK"
        title="Forest Creation UK"
      />
      <div class="packageTitle">Forest Creation,<br />UK</div></a
    >
  </div>
  <div class="text-center roundedBox fadeUp delay-125 col-1-3">
    <a href="#" @click="toggleModalChina"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/china.jpg"
        alt="Sichuan Biodigester China"
        title="Sichuan Biodigester China"
      />
      <div class="packageTitle">Sichuan Biodigester,<br />China</div></a
    >
  </div>
  <div class="text-center roundedBox fadeUp delay-150 col-1-3">
    <a href="#" @click="toggleModalTurkey"
      ><img
        class="imgRound imgWidth-200"
        src="/img/photos/turkey.jpg"
        alt="Soma Wind Power Turkey"
        title="Soma Wind Power Turkey"
      />
      <div class="packageTitle">Soma Wind Power,<br />Turkey</div></a
    >
  </div>
  <div v-if="showModalBorneo">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalBorneo">
        <p>
          The Rimba Raya Biodiversity Reserve REDD+ project in Borneo, Indonesia
          preserves carbon-dense tropical peatlands by helping to halt
          deforestation.
        </p>
        <p>
          <span class="greenText">65K</span> hectares of forest protected,
          avoiding conversion to palm oil plantations
        </p>
        <p>
          <span class="greenText">2.5K</span> households aided in community
          development
        </p>
        <p>
          <span class="greenText">105K</span> endangered Borneo Orangutans
          protected
        </p>
      </Modal>
    </div>
  </div>
  <div v-if="showModalUS">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalUS">
        <p>
          Grassland projects preserve prairie ecosystems in the United States,
          avoiding conversion of these natural landscapes to agriculture.
          Grasslands are home to rare, endangered and threatened flora and
          fauna.
        </p>
        <p>
          Native grazing animals, including bison, elk, and deer, are used to
          naturally maintain the health of the grassland.
        </p>
        <p>
          <span class="greenText">90%</span> of grassland biomass is in the long
          roots of he plants, keeping carbon below ground
        </p>
        <p>
          <span class="greenText">23K</span> acres of never plowed rangeland
          naturally maintained in SE Colorado
        </p>
      </Modal>
    </div>
  </div>
  <div v-if="showModalMalawi">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalMalawi">
        <p>
          Through the combination of forest protection and the distribution of
          clean cookstoves, the project helps deliver significant emissions
          reductions, protect biodiversity, and address the health risks of
          indoor air pollution in Malawi.
        </p>
        <p>
          <span class="greenText">170K</span> hectares of forest
          targeted for conservation
        </p>
        <p><span class="greenText">8.5M</span> trees planted</p>
        <p>
          <span class="greenText">225K</span> people can benefit from
          forest-based sustainable livelihoods
        </p>
      </Modal>
    </div>
  </div>
  <div v-if="showModalUK">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalUK">
        <p>
          Forest Creation projects under the Woodland Carbon Code deliver
          independently verified social and environmental benefits.
        </p>
        <p>
          Woodlands improve air quality and provide wildlife habitat, timber, and
          wood fuel, and sites for public recreation.
        </p>
        <p>
          They provide opportunities for community engagement, volunteering,
          &amp; education.
        </p>
        <p><span class="greenText">248</span> certified and planted projects</p>
        <p>
          <span class="greenText">113K</span> hectares certified and planted
        </p>
        <p><span class="greenText">237</span> projects under development</p>
      </Modal>
    </div>
  </div>
  <div v-if="showModalChina">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalChina">
        <p>
          This Gold Standard CDM project distributes small-scale biogas plants
          to low-income rural households with livestock across the Sichuan
          Province of China.
        </p>
        <p>
          The project provides clean and affordable energy for homes and
          fertilizer for agriculture.
        </p>
        <p>
          <span class="greenText">79%</span> of the 90 million people living in
          rural Sichuan use solid fuel for cooking
        </p>
        <p>
          <span class="greenText">395K</span> biogas plants installed across the
          Sichuan Province to qualifying low-income rural households
        </p>
        <p>
          <span class="greenText">14.5%</span> of total greenhouse gas emissions
          come from the livestock sector globally
        </p>
      </Modal>
    </div>
  </div>
  <div v-if="showModalTurkey">
    <div class="clientModalConstrainer">
      <Modal theme="modalImage" @close="toggleModalTurkey">
        <p>
          This wind power project spans provinces in the western Anatolia region
          of Turkey.
        </p>
        <p>
          The project supports an essential switch to renewable energy that can
          meet the country's rapidly increasing energy demand.
        </p>
        <p>
          <span class="greenText">465</span> GWh of clean electricity generated
          per year
        </p>
        <p>
          <span class="greenText">119</span> turbines with a total capacity of
          140 MW
        </p>
        <p>
          <span class="greenText">19%</span> of the country's demand for
          electricity could be provided by wind by 2030
        </p>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      showModalBorneo: false,
      showModalUS: false,
      showModalMalawi: false,
      showModalUK: false,
      showModalChina: false,
      showModalTurkey: false,
    };
  },
  methods: {
    toggleModalBorneo() {
      this.showModalBorneo = !this.showModalBorneo;
    },
    toggleModalUS() {
      this.showModalUS = !this.showModalUS;
    },
    toggleModalMalawi() {
      this.showModalMalawi = !this.showModalMalawi;
    },
    toggleModalUK() {
      this.showModalUK = !this.showModalUK;
    },
    toggleModalChina() {
      this.showModalChina = !this.showModalChina;
    },
    toggleModalTurkey() {
      this.showModalTurkey = !this.showModalTurkey;
    },
  },
};
</script>

<style scoped>
.greenText,
.packageTitle {
  font-size: 24px;
  font-weight: 900;
}
.clientModalConstrainer p {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  text-align: left;
}
.greenText {
  color: #7cb02a;
}
.packageTitle {
  color: #000;
  line-height: 1.4em;
  text-transform: uppercase;
}
</style>