<template>
  <div class="header1">Methodology</div>
  <div class="text-center fadeRight delay-25 col-1-3">
    <div class="blueHeader">
      <h2>Discovery</h2>
    </div>
    <div class="floatImage">
      <a href="/services/tech-audit">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-tech-audit.png"
          alt="Tech Audit"
          title="Tech Audit"
      /></a>
      <div class="packageTitle">Tech Audit</div>
    </div>
  </div>
  <div class="text-center fadeRight delay-50 col-1-3">
    <div class="blueHeader">
      <h2>
        Design &amp;<br />
        Implementation
      </h2>
    </div>
    <div class="floatImage">
      <a href="#" @click="toggleModalProjectManagement">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-project-management.png"
          alt="Project Management"
          title="Project Management"
      /></a>
      <div class="packageTitle">Project Management</div>
    </div>
    <div class="floatImage">
      <a href="#" @click="toggleModalLogistics">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-logistics.png"
          alt="Logistics"
          title="Logistics"
      /></a>
      <div class="packageTitle">Logistics</div>
    </div>
    <div class="floatImage">
      <a href="#" @click="toggleModalFinancialAgreements">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-financial-agreements.png"
          alt="Financial Agreements"
          title="Financial Agreements"
      /></a>
      <div class="packageTitle">Financial Agreements</div>
    </div>
  </div>
  <div class="text-center fadeRight delay-75 col-1-3">
    <div class="blueHeader">
      <h2>Customer<br />Support</h2>
    </div>
    <div class="floatImage">
      <a href="/solutions/23">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-remote-monitoring.png"
          alt="Remote Monitoring"
          title="Remote Monitoring"
      /></a>
      <div class="packageTitle">Remote Monitoring</div>
    </div>
    <div class="floatImage">
      <a href="/services/online-support">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-online-support.png"
          alt="Online Support"
          title="Online Support"
      /></a>
      <div class="packageTitle">Online Support</div>
    </div>
    <div class="floatImage">
      <a href="#" @click="toggleModalEngineers">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-engineers.png"
          alt="Engineers"
          title="Engineers"
      /></a>
      <div class="packageTitle">Engineers</div>
    </div>
    <div class="floatImage">
      <a href="/services/tap-for-service">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-tap-4-service.png"
          alt="On the Device Tap 4 Service"
          title="On the Device Tap 4 Service"
      /></a>
      <div class="packageTitle">On the Device Tap for Service</div>
    </div>
    <div class="floatImage">
      <a href="#" @click="toggleModalHelpdesk">
        <img
          class="imgRound imgWidth"
          src="/img/photos/service-support-helpdesk.png"
          alt="Helpdesk &amp; Remote Fix"
          title="Helpdesk &amp; Remote Fix"
      /></a>
      <div class="packageTitle">Helpdesk &amp; Remote Fix</div>
    </div>
  </div>
  <div class="row">
    <div class="col-1-3">
      <button
        class="buttonPink fadeUp delay-125"
        @click="toggleModalAccreditations"
      >
        Accreditations
      </button>
    </div>
    <div class="col-1-3">
      <a href="/services/account-management">
        <button class="buttonPink fadeUp delay-125">Account Management</button>
      </a>
    </div>
    <div class="col-1-3">
      <button
        class="buttonPink fadeUp delay-125"
        @click="toggleModalGlobalServices"
      >
        Global Services
      </button>
    </div>
  </div>
  <div v-if="showModalProjectManagement">
    <Modal theme="modalImage" @close="toggleModalProjectManagement">
      <img
        class="imgFull"
        :src="'/img/services/project-management.jpg'"
        alt="Project Management"
        title="Project Management"
      />
    </Modal>
  </div>
  <div v-if="showModalLogistics">
    <Modal theme="modalImage" @close="toggleModalLogistics">
      <img
        class="imgFull"
        :src="'/img/services/logistics.jpg'"
        alt="Logistics"
        title="Logistics"
      />
    </Modal>
  </div>
  <div v-if="showModalFinancialAgreements">
    <Modal theme="modalImage" @close="toggleModalFinancialAgreements">
      <img
        class="imgFull"
        :src="'/img/services/financial-agreements.jpg'"
        alt="Financial Agreements"
        title="Financial Agreements"
      />
    </Modal>
  </div>
  <div v-if="showModalEngineers">
    <Modal theme="modalImage" @close="toggleModalEngineers">
      <img
        class="imgFull"
        :src="'/img/services/engineers.jpg'"
        alt="Engineers"
        title="Engineers"
      />
    </Modal>
  </div>
  <div v-if="showModalHelpdesk">
    <Modal theme="modalImage" @close="toggleModalHelpdesk">
      <img
        class="imgFull"
        :src="'/img/services/helpdesk.jpg'"
        alt="Engineers"
        title="Engineers"
      />
    </Modal>
  </div>
  <div v-if="showModalAccreditations">
    <Modal theme="modalImage" @close="toggleModalAccreditations">
      <img
        class="imgFull"
        :src="'/img/services/accreditations.jpg'"
        alt="Accreditations"
        title="Accreditations"
      />
    </Modal>
  </div>
  <div v-if="showModalGlobalServices">
    <Modal theme="modalImage" @close="toggleModalGlobalServices">
      <img
        class="imgFull"
        :src="'/img/services/global-services.jpg'"
        alt="Global Services"
        title="Global Services"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      showModalProjectManagement: false,
      showModalLogistics: false,
      showModalFinancialAgreements: false,
      showModalEngineers: false,
      showModalHelpdesk: false,
      showModalAccreditations: false,
      showModalGlobalServices: false,
    };
  },
  methods: {
    toggleModalProjectManagement() {
      this.showModalProjectManagement = !this.showModalProjectManagement;
    },
    toggleModalLogistics() {
      this.showModalLogistics = !this.showModalLogistics;
    },
    toggleModalFinancialAgreements() {
      this.showModalFinancialAgreements = !this.showModalFinancialAgreements;
    },
    toggleModalEngineers() {
      this.showModalEngineers = !this.showModalEngineers;
    },
    toggleModalHelpdesk() {
      this.showModalHelpdesk = !this.showModalHelpdesk;
    },
    toggleModalAccreditations() {
      this.showModalAccreditations = !this.showModalAccreditations;
    },
    toggleModalGlobalServices() {
      this.showModalGlobalServices = !this.showModalGlobalServices;
    },
  },
};
</script>

<style scoped>
.floatImage,
.floatImage .imgWidth {
  width: 100%;
  max-width: 140px;
  margin: 8px;
}
.blueHeader h2,
.packageTitle {
  text-transform: uppercase;
}
.floatImage {
  display: inline-block;
  vertical-align: top;
}
.blueHeader {
  background-color: #c6e8f6;
  display: block;
  border-radius: 12px;
  padding: 18px 24px;
  height: 48px;
}
.blueHeader h2 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25em;
}
p.pQuote {
  font-size: 18px;
}
.horizontalRow {
  height: 2px;
  background-color: #000;
  width: 60%;
  margin: 0 auto;
}
.header1,
.header2,
.header3,
.packageTitle {
  color: #000;
  font-weight: 900;
}
.header1 {
  font-size: 36px;
}
.header2,
.header3 {
  font-size: 24px;
}
.header3 {
  max-width: 800px;
  margin: 0 auto;
  clear: both;
}
.packageTitle {
  font-size: 16px;
  line-height: 1em;
}
button {
  margin: 8px 0;
}
@media screen and (max-width: 1000px) {
  .floatImage,
  .floatImage .imgWidth {
    max-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .floatImage {
    max-width: 100%;
  }
  .floatImage .imgWidth {
    max-width: 20%;
  }
}
</style>
