<template>
  <div class="row">
    <img
      src="/img/content/our-purpose.jpg"
      class="imgAuto fadeIn"
      alt="We help businesses work smarter and work safer, in a more sustainable world"
      title="We help businesses work smarter and work safer, in a more sustainable world"
    />
  </div>
  <div class="row">
    <div class="col-1-3">
      <img
        src="/img/content/purpose_people.png"
        class="imgAuto fadeUp delay-25"
        alt="Motivate and empower your workforce to enhance productivity"
        title="Motivate and empower your workforce to enhance productivity"
      />
    </div>
    <div class="col-1-3">
      <img
        src="/img/content/purpose_process.png"
        class="imgAuto fadeUp delay-25"
        alt="Make the complicated simple to optimise operational efficiency"
        title="Make the complicated simple to optimise operational efficiency"
      />
    </div>
    <div class="col-1-3">
      <img
        src="/img/content/purpose_technology.png"
        class="imgAuto fadeUp delay-25"
        alt="Digital enablers that bring people and process together"
        title="Digital enablers that bring people and process together"
      />
    </div>
  </div>
  <div class="row fadeUp delay-50">
    <div class="col-1-5">
      <img
        src="/img/content/icon_blue_lightbulb.png"
        class="imgIcon"
        alt="icon blue lightbulb"
        title="icon blue lightbulb"
      />
      <h2>Create &amp; optimise workplaces</h2>
      <p>to ensure employees can work collaboratively anywhere anytime</p>
    </div>
    <div class="col-1-5">
      <img
        src="/img/content/icon_blue_shield.png"
        class="imgIcon"
        alt="icon blue shield"
        title="icon blue shield"
      />
      <h2>Keep workforces compliant &amp; secure</h2>
      <p>by protecting your business IP &amp; minimise threats</p>
    </div>
    <div class="col-1-5">
      <img
        src="/img/content/icon_blue_gbp.png"
        class="imgIcon"
        alt="icon blue gbp"
        title="icon blue gbp"
      />
      <h2>Maximise financial efficiency</h2>
      <p>by reducing and controlling operational costs</p>
    </div>
    <div class="col-1-5">
      <img
        src="/img/content/icon_blue_star.png"
        class="imgIcon"
        alt="icon blue star"
        title="icon blue star"
      />
      <h2>Enhance your customer experience</h2>
      <p>so you can gain and maintain a competitive edge</p>
    </div>
    <div class="col-1-5">
      <img
        src="/img/content/icon_blue_leaf.png"
        class="imgIcon"
        alt="icon blue leaf"
        title="icon blue leaf"
      />
      <h2>Reduce impact on the environment</h2>
      <p>by achieving sustainability goals &amp; awareness</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2,
p {
  color: #005d87;
  padding: 0;
  margin: 0;
}
h2 {
  font-size: 20px;
  font-weight: 700;
}
p {
  font-size: 18px;
  font-weight: 300;
}
</style>
