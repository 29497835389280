<template class="contentClass">
  <div class="navContainer">
    <ul class="navBar">
      <li
        v-for="nav in navItems"
        :key="nav.navId"
        class="navItem"
        :style="{ 'background-color': nav.navColor }"
      >
        <a :href="nav.navUrl"
          ><fa :icon="nav.navIcon" />
          <span style="padding-left: 20px">{{ nav.title }}</span></a
        >
      </li>
    </ul>
  </div>
  <router-view />
  <div class="navBrowser">
    <button @click="back">&lt;</button>&nbsp;
    <button @click="forward">&gt;</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [],
    };
  },
  mounted() {
    fetch("/data/db.json")
      .then((res) => res.json())
      .then((data) => (this.navItems = data['navItems']))
      .catch((err) => console.log(err.message));
  },
  methods: {
    redirect() {},
    back() {
      this.$router.go(-1);
    },
    forward() {
      this.$router.go(1);
    },
  },
};
</script>

<style>
body {
  background: url(/public/img/office-drawing-grad.jpg) center center/cover
    no-repeat fixed;
}
.navBrowser {
  position: absolute;
  top: 25px;
  right: 0;
  width: 120px;
}
.navBar {
  position: absolute;
  top: 20%;
  bottom: 0;
  left: 0;
  margin: auto 0;
  z-index: 99999999999;
}
.navItem {
  clear: both;
  float: left;
  width: 54px;
  height: 54px;
  margin: 10px;
  padding: 0;
  text-align: left;
  border-radius: 50px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.25s;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  text-shadow: 1px 1px 5px #666;
  box-shadow: 0 2px 8px #666;
}
.navItem:hover {
  width: 280px;
  filter: brightness(1.25);
}
.navItem a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 14px 14px 16px 18px;
}
</style>
