<template>
  <h1>Tech Audit</h1>
  <div class="row">
    <div class="col-1-3">
      <div class="head1">24/7 Support via our</div>
      <div class="head2">Client Portal</div>
      <div>
        <img src="/img/services/client-portal-qr.png" />
      </div>
      <div class="head3">Scan to sign up</div>
    </div>
    <div class="col-2-3">
      <video width="320" height="240" controls>
        <source src="/videos/services-tech-audit.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
  <div class="row fadeUp topBlue">
    <div class="col-1-4">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>Raise support &amp; consumable requests</div>
    </div>
    <div class="col-1-4">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>View &amp; manage your fleet</div>
    </div>
    <div class="col-1-4">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>View historical requests</div>
    </div>
    <div class="col-1-4">
      <div><img src="../../assets/img/bullet-blue-plus.png" /></div>
      <div>View consumable levels</div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.head1,
.head2,
.head3 {
  color: #000;
  font-weight: 900;
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 48px;
  text-transform: uppercase;
}
.head3 {
  font-size: 28px;
}
h2 {
  text-transform: uppercase;
  font-size: 28px;
}
.topBlue {
  background: #2e779a;
  border-radius: 25px;
  padding: 25px;
  color: #fff;
  font-size: 18px;
}
.topBlue h2 {
  color: #fff;
  font-size: 24px;
  text-transform: none;
}
video {
  border-radius: 25px;
}
</style>