!<template>
  <div class="col-1-4">
    <img
      class="imgDDS fadeLeft"
      src="/img/content/mws-digital-workplace.png"
      alt="Digital Workplace"
    />
  </div>
  <div class="topRow">
    <div class="col-1-2 roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/4">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/automation.jpg"
          alt="Automation"
          title="Automation"
        />
        <div class="packageTitle">Automation</div>
        <div class="packageDesc">
          Automatically extract, process, and file data; with minimal input.
        </div>
      </a>
    </div>
    <div class="col-1-2 roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/5">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/cloud-working.jpg"
          alt="Cloud Working"
          title="Cloud Working"
        />
        <div class="packageTitle">Cloud Working</div>
        <div class="packageDesc">
          Hosting print, telecoms, and information management in the cloud.
        </div>
      </a>
    </div>
  </div>
  <div class="bottomRow">
    <div class="col-1-2 roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/6">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/information-management.jpg"
          alt="Document Management"
          title="Document Management"
        />
        <div class="packageTitle">Document Management</div>
        <div class="packageDesc">
          Easily locate and access information with maximum convenience, and
          security.
        </div>
      </a>
    </div>
    <div class="col-1-2 roundFloat text-center fadeRight delay-50 roundPadding">
      <a href="/solutions/7">
        <img
          class="imgRound imgWidth-200"
          src="/img/photos/digital-mail-2-1024x683.jpg"
          alt="Hybrid Mail"
          title="Hybrid Mail"
        />
        <div class="packageTitle">Hybrid Mail</div>
        <div class="packageDesc">
          Digitising your physical outbound mail processes.
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
img.imgWidth-200 {
  max-width: 220px;
}
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
img.imgDDS {
  display: none;
  visibility: hidden;
  height: 100%;
  max-height: 900px;
  width: auto;
}
@media screen and (min-width: 800px) {
  img.imgDDS,
  img.imgFull {
    position: absolute;
    bottom: 0;
  }
  .topRow {
    position: absolute;
    left: 30%;
    top: 5%;
  }
  .bottomRow {
    position: absolute;
    left: 40%;
    top: 50%;
  }
  .col-1-2 {
    width: 40%;
    float: left;
  }
  .packageTitle {
    font-weight: 900;
    color: #000;
    font-size: 1.25vw;
  }
  img.imgDDS {
    visibility: visible;
    display: initial;
    left: 6%;
    width: 100%;
    max-width: 30%;
    height: auto;
  }
  img.imgFull {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>