<template>
  <div v-if="job">
    <h1>{{ job.title }} : Explore Packages</h1>
    <div class="row">
      <div
        v-for="explorePackage in job.explorePackages"
        :key="explorePackage.packageId"
      >
        <div
          class="text-center roundedBox fadeUp delay-50 col-1-2"
          @click="toggleModalPackage"
        >
          <img
            class="imgRound imgWidth-200"
            :src="'/img/photos/' + explorePackage.packageSlug + '.jpg'"
            :alt="explorePackage.packageName"
            :title="explorePackage.packageName"
          />
          <div class="packageTitle">{{ explorePackage.packageName }}</div>
        </div>
        <div v-if="showModalPackage">
          <Modal theme="ModalPackage" @close="toggleModalPackage">
            <div
              class="popupBox"
              v-for="explorePackage in job.explorePackages"
              :key="explorePackage.packageId"
            >
              <div>
                <div>
                  <img
                    class="imgRound imgWidth-200"
                    :src="'/img/photos/' + explorePackage.packageSlug + '.jpg'"
                    :alt="explorePackage.packageName"
                    :title="explorePackage.packageName"
                  />
                </div>
              </div>
              <div class="packageTitle">{{ explorePackage.packageName }}</div>
              <div v-html="explorePackage.packageContent"></div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading solution details...</p>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  props: ["id"],
  data() {
    return {
      color: "white",
      job: null,
      showModalPackage: false,
    };
  },
  mounted() {
    fetch("/data/db.json")
        .then((res) => res.json())
        .then((data) => (this.job = data['jobs'][this.id]))
        .catch((err) => console.log(err.message));
  },
  methods: {
    toggleModalPackage() {
      this.showModalPackage = !this.showModalPackage;
    },
  },
};
</script>

<style scoped>
.packageTitle {
  font-size: 24px;
  color: #000;
  font-weight: 900;
  line-height: 3em;
}
</style>