<template>
  <div>
    <h1>Solution Focus: Print Releaf</h1>
    <div class="solutionChallenges fadeUp delay-25">
      <div>
        <p class="solutionChallengesHeader">The Highlights</p>
        <ul class="solutionChallengesBulletCybersecurity">
          <li>Offset your printing with reforestation</li>
          <li>Full reporting dashboard to monitor your impact</li>
          <li>Hit your sustainability goals</li>
          <li>Simplest way to reduce your carbon footprint</li>
        </ul>
      </div>
    </div>
    <div class="solutionImpact fadeUp delay-50">
      <div class="contentImg">
        <img
          class="contentImage"
          src="/img/content/printreleaf-how-it-works.png"
        />
      </div>
      <div class="buttonRow">
        <div class="solutionImpactClient fadeUp delay-100">
          <a href="#" @click="toggleModalWorks">
            <div class="solutionImpactBox">
              <div>
                <img src="/img/icon/print-releaf.png" />
              </div>
            </div>
          </a>
          <div class="solutionImpactText">
            <div>Print Releaf:<br />At a glance</div>
          </div>
        </div>
        <div class="solutionImpactClient fadeUp delay-100">
          <a href="#" @click="toggleModalVideo">
            <div class="solutionImpactBox">
              <img src="../../assets/img/play-icon.png" />
            </div>
          </a>
          <div class="solutionImpactText">Explainer Video</div>
        </div>
        <div class="solutionImpactClient fadeUp delay-100">
          <a href="#" @click="toggleModalWorks2">
            <div class="solutionImpactBox">
              <div>
                <img src="/img/icon/gbp.png" />
              </div>
            </div>
          </a>
          <div class="solutionImpactText">
            <div>Pricing<br />Breakdown</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalVideo">
      <Modal theme="modalVideo" @close="toggleModalVideo">
        <video width="320" height="240" controls autoplay>
          <source src="/videos/printreleaf.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </div>
    <div v-if="showModalWorks">
      <Modal theme="modalImage" @close="toggleModalWorks">
        <img
          class="imgFull"
          src="/img/how-it-works/print-releaf-at-a-glance.jpg"
          alt="Print Releaf at a glance"
          title="Print Releaf at a glance"
        />
      </Modal>
    </div>
    <div v-if="showModalWorks2">
      <Modal theme="modalImage" @close="toggleModalWorks2">
        <img
          class="imgFull"
          src="/img/how-it-works/solution-focus-print-releaf.jpg"
          alt="Solution Focus Print Releaf"
          title="Solution Focus Print Releaf"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  props: ["id"],
  data() {
    return {
      color: "white",
      showModalVideo: false,
      showModalWorks: false,
      showModalWorks2: false,
    };
  },
  mounted() {
    fetch("/data/db.json")
        .then((res) => res.json())
        .then((data) => (this.job = data['jobs'][this.id]))
        .catch((err) => console.log(err.message));
  },
  methods: {
    toggleModalVideo() {
      this.showModalVideo = !this.showModalVideo;
    },
    toggleModalWorks() {
      this.showModalWorks = !this.showModalWorks;
    },
    toggleModalWorks2() {
      this.showModalWorks2 = !this.showModalWorks2;
    },
  },
};
</script>

<style scoped>
.buttonRow,
.clientRow,
.templateSingleHalf {
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
img.contentImage {
  width: 100%;
  height: auto;
}
.contentImg {
  padding: 32px 0;
}
.popupBox h2,
p.solutionImpactHeader {
  text-transform: uppercase;
  font-weight: 700;
}
body {
  background-color: v-bind(color);
}
.solutionChallenges {
  display: block;
  border-radius: 25px;
  background-color: #2e769a;
  width: 24%;
  padding: 24px;
  margin: 2%;
  color: #fff;
  text-align: left;
  float: left;
  box-shadow: 2px 2px 8px #999;
}
.solutionChallengesDesc {
  font-size: 24px;
  font-style: italic;
  line-height: 1.2em;
  display: inline-block;
  padding-bottom: 24px;
  border-bottom: 1px solid #80b5d0;
}
.solutionChallengesHeader {
  font-size: 24px;
  font-weight: 700;
}
ul.solutionChallengesBullet,
ul.solutionChallengesBulletCybersecurity {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.solutionChallengesBullet li {
  background: url("../../assets/img/liYellowExclamation.png") top left
    no-repeat;
  padding: 0 0 36px 60px;
}
ul.solutionChallengesBulletCybersecurity li {
  background: url("../../assets/img/bullet-blue-plus.png") top left no-repeat;
  padding: 16px 0 36px 72px;
}
.solutionImpact {
  display: block;
  float: left;
  padding: 0 24px;
  margin: 0 2%;
  width: 55%;
}
p.solutionImpactHeader {
  color: #000;
  font-size: 36px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
p.solutionImpactHeader:after,
p.solutionImpactHeader:before {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 47%;
  height: 2px;
  content: "";
  background-color: #000;
}
p.solutionImpactHeader:before {
  margin-left: -50%;
  text-align: right;
  margin-right: 18px;
}
p.solutionImpactHeader:after {
  margin-left: 3%;
}
.buttonRow,
.clientRow {
  display: grid;
}
.solutionImpactClient {
  text-align: center;
  margin-bottom: 120px;
}
.solutionImpactClient img {
  object-fit: contain;
  height: 140px;
  width: 280px;
  padding-bottom: 16px;
}
.solutionImpactBox,
.solutionImpactBox img {
  width: 80px;
  height: 80px;
}
.solutionImpactClient button,
.solutionImpactClient img {
  text-align: center;
  display: block;
  clear: both;
  margin: 0 auto;
}
.solutionImpactBox {
  display: block;
  background: #31a3d0;
  background: linear-gradient(90deg, #31a3d0 0, #015f88 100%);
  box-shadow: 0 2px 8px #666;
  color: #fff;
  font-size: 24px;
  padding: 0;
  margin: 0 auto;
  border-radius: 25px;
  transition: 0.25s;
}
.solutionImpactBox:hover {
  filter: brightness(150%);
}
.solutionImpactText {
  color: #000;
  font-weight: 900;
  font-size: 18px;
  margin: 12px auto;
  text-align: center;
}
.templateSingleContainer {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}
.templateSingleHeader,
.templateSingleQuote {
  color: red;
  line-height: 1.2em;
  margin: 0;
  padding: 24px;
}
.templateSingleQuote {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
}
.templateSingleDetails {
  color: #000;
  line-height: 1.2em;
  margin: 0;
  padding: 36px 48px;
  background-color: #d8d0ffd8;
  box-shadow: 0 5px 10px 0 #00000063;
  border-radius: 25px;
  font-size: 18px;
}
.templateSingleHeader {
  font-size: 36px;
  font-weight: 900 !important;
}
.templateSingleHalf {
  text-align: left;
  display: grid;
}
.templateSingleBreak {
  width: 75%;
  height: 5px;
  margin: 24px 0 24px 28px;
  background-color: red;
}
</style>
