<template>
  <h1>Who We Work With</h1>
  <div class="row">
    <div class="flexContainer">
      <div
        v-for="linkItem in workWithItem"
        :key="linkItem.id"
        class="flexItem"
        :style="'background:linear-gradient(0deg, rgba(8, 94, 136, 0.8), rgba(8, 94, 136, 0.8)),url(img/photos/'+ linkItem.photo +'.jpg) no-repeat center center; background-size: cover;'"
        @click="toggleModalImage(linkItem.id, linkItem.img)"
      >
        <div v-html="linkItem.title"></div>
      </div>
    </div>
  </div>

  <div v-if="this.boxesDisplayed.includes(true)">
    <Modal theme="modalImg" @close="toggleModalImage(-1, null)">
      <img
        class="imgFull"
        :src="'/img/who-we-work-with/' + this.targetImage + '.jpg'"
      />
    </Modal>
  </div>
</template>

<script>
var boxesDisplayed = new Array(11).fill(false);
var targetImage = null;

import Modal from "../components/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      workWithItem: [],
      boxesDisplayed: [11],
    };
  },

  mounted() {
    fetch("/data/db.json")
      .then((res) => res.json())
      .then((data) => (this.workWithItem = data['workWith']))
      .catch((err) => console.log(err.message));
  },

  methods: {
    toggleModalImage(key, img) {
      if (key < 0) {
        this.boxesDisplayed = new Array(11).fill(false);
      }

      this.boxesDisplayed[key - 1] = !boxesDisplayed[key - 1];
      this.targetImage = img;
    },
  },
};
</script>

<style>
.flexContainer {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.flexItem {
  width: 18%;
  margin: 8px;
  background: #085e88;
  padding: 24px 36px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: 0 2px 8px #666;
  transition: 0.25s;
  min-height: 80px;
  justify-content: center;
  align-items: center;
}
.flexItem:hover {
  filter: brightness(150%);
  cursor: pointer;
}
@media (max-width: 1600px) {
  .flexItem {
    font-size: 20px;
    width: 24%;
  }
}
@media (max-width: 1200px) {
  .flexItem {
    font-size: 20px;
    width: 22%;
  }
}
@media (max-width: 1000px) {
  .flexItem {
    font-size: 20px;
    width: 18%;
  }
}
@media (max-width: 800px) {
  .flexContainer {
    flex-direction: column;
  }
  .flexItem {
    width: 90%;
    min-height: 60px;
  }
}
</style>
