<template>
  <h1>Carbon Neutrality</h1>
  <p class="subheader">
    The CarbonNeutral&reg; product certification helps you meet your climate
    change goals - achieve certified carbon neutral printing
  </p>
  <div class="row mainContent">
    <div class="col-1-3">
      <div class="solutionChallenges fadeUp">
        <p class="pheader">What does carbon neutral mean?</p>
        <p>
          Effectively bring a carbon footprint to zero using a combination of
          measures, including reduction and offsetting.
        </p>
        <p class="psubheader">Reduction</p>
        <ul>
          <li>Increased operating efficiency</li>
          <li>Moving to renewable energy</li>
        </ul>
        <p class="psubheader">Offsetting</p>
        <ul>
          <li>
            Investing in carbon offset projects, such as conservation and
            regeneration of forests
          </li>
          <li>Offsets have co-benefits, including:</li>
          <li>
            Further sustainable development, such as jobs for renewable energy
          </li>
          <li>Health benefits from fossil-fuel reduction</li>
          <li>Conservation of biodiversity from forestry and other projects</li>
        </ul>
      </div>
    </div>
    <div class="col-2-3 fadeRight delay-50">
      <div class="floatImage">
        <a href="/services/tap-for-service">
          <img
            class="imgWidth"
            src="/img/icon/iconTarget.png"
            alt="Deliver environmental goals without interrupting your business"
            title="Deliver environmental goals without interrupting your business"
        /></a>
        <div class="packageTitle">
          Deliver environmental goals without interrupting your business
        </div>
      </div>
      <div class="floatImage">
        <a href="/services/tap-for-service">
          <img
            class="imgWidth"
            src="/img/icon/iconHandshake.png"
            alt="Demonstrate your commitment to environmental goals"
            title="Demonstrate your commitment to environmental goals"
        /></a>
        <div class="packageTitle">
          Demonstrate your commitment to environmental goals
        </div>
      </div>
      <div class="floatImage">
        <a href="/services/tap-for-service">
          <img
            class="imgWidth"
            src="/img/icon/iconCheckCircle.png"
            alt="HP has chosen high-quality carbon offset projects that are verified by third-party standards"
            title="HP has chosen high-quality carbon offset projects that are verified by third-party standards"
        /></a>
        <div class="packageTitle">
          HP has chosen high-quality carbon offset projects that are verified by
          third-party standards
        </div>
      </div>
    </div>
  </div>
  <img
    class="fadeRight delay-100 floatBottomRight"
    src="/img/content/certified-carbon-neutral-service-logo.png"
  />
</template>

<script>
export default {};
</script>

<style scoped>
.col-1-3 {
  width: 30% !important;
}
.col-2-3 {
  width: 62% !important;
}
img.floatBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
}
.pheader {
  margin: 0;
  font-size: 28px;
  line-height: 1.4em;
  font-weight: 700;
}
.psubheader {
  font-size: 20px;
  margin-bottom: 0;
}
ul {
  padding-left: 24px;
}
ul li {
  font-size: 16px;
  padding: 0;
}
.subheader {
  font-size: 28px;
  margin-bottom: 0;
}
.packageTitle {
  font-weight: 700;
}
.mainContent .col-1-3:first-child {
  width: 20%;
}
.mainContent .col-1-3:nth-child(2) {
  width: 52%;
}
.mainContent .col-1-3:nth-child(3) {
  width: 15%;
  border-left: 2px solid #7db02b;
}
.solutionChallenges {
  background-color: rgba(125, 176, 43, 0.8);
  width: 90%;
}
.greenBox p {
  font-size: 20px;
  text-align: left;
  color: #fff;
}
.greenBox p.quoteAttrib {
  font-size: 14px;
}
.floatImage {
  width: 40%;
  margin: 8px;
}
.floatImage .imgWidth {
  width: 100%;
  max-width: 200px;
  margin: 8px;
}
.floatImage {
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 800px) {
  .col-1-3,
  .col-2-3,
  .col-1-4,
  .col-2-3 {
    width: 100% !important;
  }
  .mainContent .col-1-3:nth-child(3) {
    border-left: none;
  }
  img.floatBottomRight {
    position: relative !important;
  }
}
</style>