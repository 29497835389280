!<template>
  <div class="roundFloat text-center fadeUp delay-50 col-1-4 roundPadding">
    <a href="/solutions/0">
      <img
        class="imgRound"
        src="/img/photos/service-support-remote-monitoring.png"
        alt="Managed Print"
        title="Managed Print"
      />
      <div class="packageTitle">Managed Print</div>
      <div class="packageDesc">
        Automated toner and support to maximise your operational and financial
        efficiency.
      </div>
    </a>
  </div>
  <div class="roundFloat text-center fadeUp delay-50 col-1-4">
    <a href="/solutions/1">
      <img
        class="imgRound"
        src="/img/photos/digital-print-reprographics-man-plaid-shirt-1024x671.jpg"
        alt="Managed Print"
        title="Managed Print"
      />
      <div class="packageTitle">Production Print</div>
      <div class="packageDesc">
        High-volume print at high-speed and quality.
      </div>
    </a>
  </div>
  <div class="roundFloat text-center fadeUp delay-50 col-1-4">
    <a href="/solutions/2">
      <img
        class="imgRound"
        src="/img/photos/service-support-tap-4-service.png"
        alt="Managed Print"
        title="Managed Print"
      />
      <div class="packageTitle">Print Management</div>
      <div class="packageDesc">
        Securely manage print costs and volumes through user authenticated
        printing and analytics.
      </div>
    </a>
  </div>
  <div class="roundFloat text-center fadeUp delay-50 col-1-4 roundPadding">
    <a href="/solutions/3">
      <img
        class="imgRound"
        src="/img/photos/one-source-upload-complete-woman-on-laptop-1024x814.jpg"
        alt="Managed Print"
        title="Managed Print"
      />
      <div class="packageTitle">Print On Demand<br />(OneSource)</div>
      <div class="packageDesc">
        Print from anywhere, to anywhere with a secure print portal.
      </div>
    </a>
  </div>
  <img class="imgFull fadeUp" src="/img/content/mws-print.png" alt="Print" />
</template>

<script>
export default {};
</script>

<style scoped>
.packageTitle {
  font-size: 24px;
  font-weight: 900;
  color: #000;
}
.packageDesc {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media screen and (min-width: 1000px) {
  .roundPadding {
    margin-top: 140px;
  }
  img.imgFull {
    max-width: 55%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .roundFloat {
    z-index: 200;
  }
  .packageTitle {
    font-size: 1.25vw;
  }
  .packageDesc {
    font-size: 0.9vw;
  }
}
</style>