<template>
  <h1>Sustainability Focus</h1>
  <h2>Our Objectives</h2>
  <div class="row fadeUp">
    <img
      src="/img/content/sustainability-goals.png"
      alt="Apogee corporation esg sustainability goals chart"
      title="Apogee corporation esg sustainability goals chart"
    />
  </div>
  <div class="row text-left roundedBox fadeUp delay-50">
    <div class="col-1-4">
      <div class="titleNumber">7,152</div>
      <div class="boldNumber">Trees reforested so far...</div>
    </div>
    <div class="col-1-4">
      <div class="titleNumber">87%</div>
      <div class="boldNumber">of waste recycled</div>
    </div>
    <div class="col-1-4">
      <div class="titleNumber">227 kilos</div>
      <div class="boldNumber">
        of ocean-bound plastic cleared through Beach Clean days
      </div>
    </div>
    <div class="col-1-4">
      <div class="titleNumber">0%</div>
      <div class="boldNumber">of waste to landfill</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2,
h3,
h4,
img.imgAuto,
ul {
  margin: 0;
  padding: 0;
}
h2,
h4 {
  color: #000;
}
.roundedBox {
  border-radius: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.6);
  display: flow-root;
  padding: 0;
  background-color: #8fbb49;
}
img.imgAuto {
  width: 100%;
  height: auto;
}
.titleNumber {
  font-size: 3vw;
  text-align: center;
  color: #fff !important;
}
.boldNumber {
  font-size: 1vw;
  text-align: center;
  color: #fff !important;
}
.smallNumber {
  font-size: 18px;
  font-weight: 700;
}
.boldNumber,
.titleNumber {
  font-weight: 900;
}
.boldNumber,
.smallNumber,
.titleNumber {
  color: #000;
  padding: 0;
  margin: 0;
}
h2,
ul.blueChecks li {
  font-weight: 900 !important;
}
h3,
h4 {
  font-weight: 900;
}
h2 {
  font-size: 42px;
}
h3 {
  color: #009ade;
  font-size: 24px;
}
h4 {
  font-size: 1.75vw;
}
ul.blueChecks {
  list-style: none;
}
ul.blueChecks li {
  background: url("../../assets/img/blue-check-circle.png") top left no-repeat;
  padding: 4px 0 36px 60px !important;
  font-size: 24px;
}
</style>